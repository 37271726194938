import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';

import { formatRelativeTime } from 'theme/utils/dateUtils';
import VARIAVEIS_GLOBAIS from 'theme/utils/globalVariables';

import Text from 'components/foundation/Text';

import * as S from '../styles';

const ArticleText = ({
  data,
  titleTag = 'h3',
  showDate = false,
  showExcerpt = false,
  showRelated = false,
  titleTypography = 'heading3',
}) => {
  const relativeTime = formatRelativeTime(data?.datePublished);
  const articleTypeVideo = VARIAVEIS_GLOBAIS.PAGES_TYPES.ArticleVideo;
  const cleanExcerpt = DOMPurify.sanitize(data?.excerpt, {
    ALLOWED_TAGS: ['strong'],
  });

  return (
    <S.ArticleText>
      <Text
        as={titleTag}
        $csscolor='900'
        $variant={{ typography: titleTypography }}
      >
        {data?.title}
      </Text>

      {showExcerpt && data?.excerpt ? (
        <Text
          as='p'
          $csscolor='900'
          $variant={{ typography: 'bodyText' }}
          dangerouslySetInnerHTML={{
            __html: cleanExcerpt,
          }}
        />
      ) : null}

      {showDate ? (
        <Text
          as='span'
          $csscolor='500'
          $variant={{ typography: 'bodyTextSmall' }}
        >
          {relativeTime}
        </Text>
      ) : null}

      {showRelated && data?.related ? (
        <S.RelatedList>
          {data.related?.map(article => {
            const isTypeVideo = article?.type === articleTypeVideo;

            return (
              <S.RelatedListItem
                key={article?.uri}
                typeVideo={isTypeVideo}
              >
                <Text
                  as='span'
                  $csscolor='950'
                  $variant={{ typography: 'bodyText' }}
                >
                  {article?.title}
                </Text>
              </S.RelatedListItem>
            );
          })}
        </S.RelatedList>
      ) : null}
    </S.ArticleText>
  );
};

ArticleText.propTypes = {
  showDate: PropTypes.bool,
  titleTag: PropTypes.string,
  showExcerpt: PropTypes.bool,
  showRelated: PropTypes.bool,
  titleTypography: PropTypes.string,
  data: PropTypes.shape({
    datePublished: PropTypes.string,
    excerpt: PropTypes.string,
    title: PropTypes.string,
    related: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default React.memo(ArticleText);
