import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import useThumbnailImage from 'src/hooks/useThumbnailImage';

import Grid from 'components/foundation/layout/Grid';
import VARIAVEIS_GLOBAIS from 'theme/utils/globalVariables';

import ArticleText from './ArticleText';
import ArticleThumbnailContent from './ArticleThumbnailContent';

import * as S from './styles';

const Article = ({
  className,
  horizontal,
  hotContent,
  showRelated,
  titleTag = 'h3',
  showDate = false,
  priority = false,
  showExcerpt = false,
  thumbnailSize = 'small',
  titleTypography = 'heading3',
  data = {
    uri: '',
    title: '',
    related: [],
    categories: [],
    thumbnail: { mediaItemUrl: '', alt: '' },
  },
}) => {
  const [imgSrc, handleImageError] = useThumbnailImage(
    data?.thumbnail?.mediaItemUrl
  );

  const renderLinkWrapper = children =>
    data?.uri ? (
      <Link
        target='_top'
        prefetch={false}
        title={data?.title}
        href={`${VARIAVEIS_GLOBAIS.HOST_GAMEARENA}${data.uri}`}
      >
        {children}
      </Link>
    ) : (
      children
    );

  return (
    <S.ArticleWrapper className={className}>
      <Grid.Row>
        <Grid.Col $colValue={{ xs: 12, sm: horizontal ? 4 : 12 }}>
          {renderLinkWrapper(
            <ArticleThumbnailContent
              data={data}
              imgSrc={imgSrc}
              priority={priority}
              hotContent={hotContent}
              thumbnailSize={thumbnailSize}
              handleImageError={handleImageError}
            />
          )}
        </Grid.Col>

        <Grid.Col $colValue={{ xs: 12, sm: horizontal ? 8 : 12 }}>
          {renderLinkWrapper(
            <ArticleText
              data={data}
              titleTag={titleTag}
              showDate={showDate}
              showExcerpt={showExcerpt}
              showRelated={showRelated}
              titleTypography={titleTypography}
            />
          )}
        </Grid.Col>
      </Grid.Row>
    </S.ArticleWrapper>
  );
};

Article.propTypes = {
  showDate: PropTypes.bool,
  priority: PropTypes.bool,
  titleTag: PropTypes.string,
  horizontal: PropTypes.bool,
  hotContent: PropTypes.bool,
  showExcerpt: PropTypes.bool,
  showRelated: PropTypes.bool,
  className: PropTypes.string,
  titleTypography: PropTypes.string,
  thumbnailSize: PropTypes.oneOf(['small', 'medium', 'large']),
  data: PropTypes.shape({
    uri: PropTypes.string,
    preGame: PropTypes.bool,
    excerpt: PropTypes.string,
    datePublished: PropTypes.string,
    title: PropTypes.string.isRequired,
    thumbnail: PropTypes.shape({
      alt: PropTypes.string,
      mediaItemUrl: PropTypes.string,
    }),
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
    related: PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string,
        title: PropTypes.string,
      })
    ),
  }),
};

export default React.memo(Article);
