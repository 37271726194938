import React from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';

import Text from 'components/foundation/Text';

import * as S from '../styles';

const ArticleThumbnailContent = ({
  data,
  imgSrc,
  categoryData,
  layoutAppview,
  handleImageError,
  priority = false,
  hotContent = false,
  thumbnailSize = 'small',
}) => (
  <S.ArticleThumbnailContent
    $thumbnailSize={thumbnailSize}
    className='article__thumbnail'
  >
    <Image
      fill
      src={imgSrc}
      className='image-fill'
      priority={!!priority}
      onError={handleImageError}
      alt={data?.alt || data?.title}
      srcSet={data?.srcSet || imgSrc}
    />

    {categoryData ? (
      <S.Categories>
        <S.Category
          category={categoryData?.slug}
          $layoutAppview={layoutAppview}
          $fatherCategory={categoryData?.categoryFatherSlug}
        >
          <Text
            as='span'
            $csscolor='00'
            $cssTextTransform='uppercase'
            $variant={{ typography: 'bodyTextXXXSmall' }}
          >
            {categoryData?.name}
          </Text>
        </S.Category>
      </S.Categories>
    ) : null}

    {hotContent ? <S.HotContent data-testid='hot-content' /> : null}

    {data?.preGame ? (
      <S.PreGame>
        <Image
          alt='Time A'
          width={32}
          height={32}
          quality={100}
          src='/images/temp/article/time-a.png'
        />
        <Text
          as='span'
          $csscolor='00'
          $cssTextTransform='uppercase'
          $variant={{ typography: 'heading5' }}
        >
          Pré-game
        </Text>
        <Image
          width={32}
          height={32}
          alt='Time B'
          quality={100}
          src='/images/temp/article/time-b.png'
        />
      </S.PreGame>
    ) : null}
  </S.ArticleThumbnailContent>
);

ArticleThumbnailContent.propTypes = {
  priority: PropTypes.bool,
  hotContent: PropTypes.bool,
  layoutAppview: PropTypes.bool,
  imgSrc: PropTypes.string.isRequired,
  handleImageError: PropTypes.func.isRequired,
  thumbnailSize: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  categoryData: PropTypes.shape({
    slug: PropTypes.string,
    name: PropTypes.string,
    categoryFatherSlug: PropTypes.string,
  }),
  data: PropTypes.shape({
    alt: PropTypes.string,
    title: PropTypes.string,
    preGame: PropTypes.bool,
    srcSet: PropTypes.string,
  }).isRequired,
};

export default React.memo(ArticleThumbnailContent);
